const bg = document.querySelector('.hero-fader__bg');
const slides = bg.querySelectorAll('.hero-fader__slide');
const slideCount = slides.length;
const activeClass = '--active';

let i = 0;

function next(){
	i++;

	slides.forEach((slide) =>{
		slide.classList.remove(activeClass);
	});

	slides[i % slideCount].classList.add(activeClass);
}

setInterval(next, 5000);